import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { Tooltip } from "primereact/tooltip";
export const SelectProvider = ({
  selectedProvider,
  setSelectedProvider,
  submitRef,
  setActiveIndex,
  providers,
  hasUnCertifiedProviders,
}) => {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const selectedProviderTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex items-center gap-2">
          <div>{option.fullName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const providerOptionTemplate = (option) => {
    return (
      <div className="flex items-center">
        <div className="flex items-center gap-2">
          <div>{option.fullName}</div>
        </div>
      </div>
    );
  };

  const onSubmit = () => {
    if (!selectedProvider) {
      setError("selectedProvider", {
        type: "manual",
        message: "Please select a provider",
      });
    } else {
      clearErrors("selectedProvider");
      setActiveIndex((old) => old + 1);
    }
  };

  return (
    <div>
      <div className="mb-5 font-inter">
        <h4 className="text-title font-extrabold capitalize text-dark-purple">
          Select your provider
        </h4>
        <p className="min-w-sm w-full md:w-11/12">
          To proceed with your order, please select the provider associated with
          this order.
        </p>
      </div>
      <form
        ref={submitRef}
        className="w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-4">
          <Controller
            name="selectedProvider"
            control={control}
            defaultValue={
              providers && (providers.length === 1 ? providers[0] : null)
            }
            render={({ field }) => (
              <>
                <div className="flex">
                  <div
                    className={`${hasUnCertifiedProviders ? "w-11/12" : "w-full"} flex gap-2`}
                  >
                    <Dropdown
                      {...field}
                      value={selectedProvider}
                      onChange={(e) => {
                        setSelectedProvider(e.value);
                        field.onChange(e.value);
                        if (e.value) {
                          clearErrors("selectedProvider");
                        }
                      }}
                      options={providers}
                      optionLabel="fullName"
                      placeholder="Select your provider"
                      filter
                      filterBy="fullName"
                      showClear
                      className="md:w-14rem w-full"
                      valueTemplate={selectedProviderTemplate}
                      itemTemplate={providerOptionTemplate}
                    />
                  </div>
                  {hasUnCertifiedProviders && (
                    <div className="flex w-1/12 cursor-pointer items-center justify-center">
                      <Tooltip target=".info-icon" />
                      <span
                        className="info-icon max-w-lg"
                        data-pr-position="top"
                        data-pr-tooltip={`Do you not see an ordering provider that you previously were able to select?
                            It is possible that the ordering provider's account requires re-certification by the practice for continued access.
                             Please contact the system administrator at the practice to get all valid ordering provider accounts re-certified and enabled`}
                      >
                        <i className="pi pi-question-circle text-lg" />
                      </span>
                    </div>
                  )}
                </div>
                {providers?.length === 0 &&
                  (hasUnCertifiedProviders ? (
                    <>
                      <Message
                        severity="error"
                        className="my-3"
                        text={
                          <span>
                            <span className="font-semibold">
                              Unfortunately all ordering providers are disabled
                              within your practice.
                            </span>{" "}
                            Please contact the system administrator at the
                            practice to get all valid ordering provider accounts
                            re-certified and enabled
                          </span>
                        }
                      />
                    </>
                  ) : (
                    <div className="mt-5 flex rounded-lg bg-red-50 p-4 font-Poppins text-red-500 shadow-md">
                      <i className="pi pi-info-circle mt-1"></i>
                      <p className="pl-3">
                        You currently don't have a provider in your practice.
                        Please initiate a provider request first.
                      </p>
                    </div>
                  ))}
                {errors.selectedProvider && (
                  <p className="pt-3 text-red-500">
                    {errors.selectedProvider.message}
                  </p>
                )}
              </>
            )}
          />
        </div>
      </form>
    </div>
  );
};
