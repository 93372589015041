import { Skeleton } from "primereact/skeleton";
import React from "react";

function NotificationsSkeleton() {
  return (
    <div>
      <div className="border-rounded mb-2 flex items-center border bg-white p-5">
        <div className="flex w-[80%] flex-col">
          <Skeleton className="mb-4 p-4" width="30%" />
          <Skeleton className="p-3" width="80%" />
        </div>
        <Skeleton size="25px" className="mr-4 p-2" />
      </div>
      <div className="border-rounded mb-2 flex items-center border bg-white p-5">
        <div className="flex w-[80%] flex-col">
          <Skeleton className="mb-4 p-4" width="20%" />
          <Skeleton className="p-3" width="55%" />
        </div>
        <Skeleton size="25px" className="mr-4 p-2" />
      </div>
      <div className="border-rounded mb-2 flex items-center border bg-white p-5">
        <div className="flex w-[80%] flex-col">
          <Skeleton className="mb-4 p-4" width="40%" />
          <Skeleton className="p-3" width="65%" />
        </div>
        <Skeleton size="25px" className="mr-4 p-2" />
      </div>
    </div>
  );
}

export default NotificationsSkeleton;
