import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import VerifyEmail from "./components/signUp/VerifyEmail";
import Login from "./components/login/Login";
import EmailVerifySuccess from "./components/signUp/EmailVerifySuccess";
import SignUp from "./components/signUp/SignUp";
import IndividualNextStep from "./components/providingRendering/IndividualNextStep";
import IndividualFinishStep from "./components/providingRendering/IndividualFinishStep";
import GroupPracticeNextStep from "./components/providingRendering/GroupPracticeNextStep";
import GroupPracticeFinishStep from "./components/providingRendering/GroupPracticeFinishStep";
import ExistingGroupPractices from "./components/providingRendering/ExistingGroupPractices";
import ChooseEntity from "./components/login/ChooseEntity";
import StaffDashboard from "./components/renderingStaff/StaffDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./components/renderingStaff/Dashboard";
import RenderTransactionTracking from "./components/transactionTracking/RenderTransactionTracking";
import Settings from "./components/Settings";
import JoinEntity from "./components/providingRendering/JoinEntity";
import LocationsExplore from "./components/rendererProviderLocation/LocationsExplore";
import DelliganceAcceptance from "./components/DelliganceAcceptance";
import EmptyComponents from "./EmptyComponents";
import ProfileSettings from "./ProfileSettings";
import DepositSettings from "./components/DepositSettings";
import OrderingProvider from "./components/providingRendering/OrderingProvider";
import EditGroupPractice from "./components/settings/EditGroupPractice";
import LocationForm from "./components/rendererProviderLocation/LocationForm";
import ViewInvitation from "./components/inviteStaff/ViewInvitation";
import Google from "./components/signUp/Google";
import OAuthRegistration from "./components/signUp/OAuthRegistration";
import DeclineInvitation from "./components/inviteStaff/DeclineInvitation";
import NotFound from "./NotFound";
import Maintenance from "./Maintenance";
import AcceptInvitation from "./components/inviteStaff/AcceptInvitation";
import DeclinedInvitation from "./components/inviteStaff/DeclinedInvitation";
import EditIndividualPractice from "./components/settings/EditIndividualPractice";
import AdminRegistration from "./components/signUp/AdminRegistration";

import FindServices from "./components/orderingStaff/FindServices";
import EntityPage from "./components/orderingStaff/EntityPage";
import FavoritePractices from "./components/orderingStaff/FavoritePractices";
import { LandingPage } from "./components/landing/LandingPage";
import DashboardRendering from "./components/Dashboard";
import VerifyProvider from "./components/settings/VerifyProvider";
import { WorkLists } from "./components/admin/WorkLists";
import OrderingOrders from "./components/providingRendering/OrderingOrders";
import RemitsComplete from "./components/admin/pendingRemitsWorkList/RemitsComplete";
import { Transactions } from "./components/providingRendering/transactions/Transactions";
import { OrderSettings } from "./components/settings/OrderSettings";
import { Provider } from "./components/admin/verificationWorklist/Provider";
import OurPractices from "./components/admin/ourPractices/OurPractices";

import AdminLogin from "./components/signUp/AdminLogin";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import { ForgetPassword } from "./components/login/ForgetPassword";
import { ResetPassword } from "./components/login/ResetPassword";
import { PersonateAuthGaurd } from "./components/PersonateAuthGaurd";
import { AdminAuthGaurd } from "./components/AdminAuthGaurd";
import { AdminLoginAuthGaurd } from "./components/AdminLoginAuthGaurd";
import QuestionMaker from "./components/providingRendering/QuestionMaker";
import CreateNewService from "./components/CreateNewService";
import { ManageCreatedServices } from "./components/services/ManageCreatedServices";
import RequestService from "./components/RequestService";
import { BillableItem } from "./components/services/billableItems/BillableItem";
import TransactionTracking from "./components/transactionTracking/TransactionTracking";
import ManageAdmins from "./components/admin/manageAdmins/ManageAdmins";
import { OrderService } from "./components/services/OrderService";
import Seq from "./components/Seq";
import UserRoleGuard from "./components/guards/UserRoleGuard";
import IsHiddenGuard from "./components/guards/IsHiddenGuard";
import OrderDetails from "./components/providingRendering/OrderDetails";
import ServiceTemplateMaker from "./ServiceTemplateMaker";
import CreateQuestion from "./components/CreateQuestion";
import ManageServices from "./components/services/ManageServices";
import SingleQuestionForm from "./components/services/SingleQuestionForm";
import ExternalLoginGuard from "./components/guards/ExternalLoginGuard";
import CreateServiceTemplate from "./components/services/CreateServiceTemplate";
import useTokenData from "./hooks/useTokenData";
import PaymentSuccess from "./components/PaymentSuccess";
import EmailSuccessGuard from "./components/guards/EmailSuccessGuard";
import SessionExpiredModal from "./components/SessionExpiredModal";
import TestService from "./TestService";
import LandingPageGuard from "./components/guards/LandingPageGuard";
import EmailOrderUpdate from "./components/EmailOrderUpdate";
import AuthGuard from "./components/guards/AuthGuard";
import Welcome from "./components/Welcome";
import DocumentsLibrary from "./components/services/documentsLibrary/DocumentsLibrary";
import WelcomeProvider from "./components/WelcomeProvider";
import ManageOrderFromProvider from "./components/services/ManageOrderFromProvider";
import OrderAccepted from "./components/services/manageOrderByProvider/OrderAccepted";
import OrderDenied from "./components/services/manageOrderByProvider/OrderDenied";
import ExpiredLink from "./components/services/manageOrderByProvider/ExpiredLink";
import ConfirmProviderGuard from "./components/guards/ConfirmProviderGuard";
import ProviderAlreadyAccepted from "./components/services/manageOrderByProvider/ProviderAlreadyAccepted";
import ProviderAlreadyDenied from "./components/services/manageOrderByProvider/ProviderAlreadyDenied";
import EmbeddedStripeCheckout from "./components/embeddedStripe/EmbeddedStripeCheckout";
import EmbeddedStripeReturn from "./components/embeddedStripe/EmbeddedStripeReturn";
import PrivacyPreferences from "./components/settings/PrivacyPreferences";

import PracticeDetails from "./components/admin/ourPractices/PracticeDetails";
import CompleteProviderVerification from "./components/settings/CompleteProviderVerification";
import CompleteVerificationSuccess from "./components/settings/CompleteVerificationSuccess";

import BillableItemsReport from "./components/services/billableItems/BillableItemsReport";
import TermsAndConditions from "./components/legal/TermsAndConditions";
import Legal from "./components/legal/Legal";
import SystemExpire from "./components/admin/manageAdmins/SystemExpire";
import ExpireSystemAdminGuard from "./components/guards/ExpireSystemAdminGuard";
import ChangePassword from "./components/settings/ChangePassword";
import Security from "./components/settings/Security";
import DialogUpdatePin from "./components/settings/DialogUpdatePin";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import AdminGlobalInfo from "./components/settings/AdminGlobalInfo";
import CaptureViaMobile from "./components/CaptureViaMobile";
import EnvironmentSettings from "./components/settings/EnvironmentSettings";
import ManageUserNotifs from "./components/settings/ManageUserNotifs";
import PracticeNotificationSettings from "./components/PracticeNotificationSettings";
import UserNotificationSettings from "./components/UserNotificationSettings";
import SystemAdminNotifs from "./components/settings/SystemAdminNotifs";

const AppRoutes = () => {
  const location = useLocation();
  // const { isCompletedRegister } = useExternalLogin();
  const { isCompletedRegister } = useTokenData();
  const isAdminRegistrationRoute =
    location.pathname === "/admin/registration" &&
    location.search.includes("I=");

  return (
    <Routes>
      <Route path="/dialog" element={<SessionExpiredModal />} />

      <Route element={<ExpireSystemAdminGuard />}>
        <Route path="/admin/expired" element={<SystemExpire />} />
      </Route>

      <Route element={<LandingPageGuard />}>
        <Route path="/" element={<LandingPage />} />
      </Route>
      <Route element={<AuthGuard />}>
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/welcome-provider" element={<WelcomeProvider />} />
      </Route>
      <Route path="/googleAuth" element={<Google />} />
      <Route path="/completeRegRedirect" element={<OAuthRegistration />} />
      <Route
        exact
        path="/completeRegistration"
        element={
          isCompletedRegister == "False" ? (
            <Navigate to="/completeRegRedirect" replace={false} />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/verifyEmail" element={<VerifyEmail />} />
      <Route element={<EmailSuccessGuard />}>
        <Route path="/verifySuccess" element={<EmailVerifySuccess />} />
      </Route>
      <Route
        path="/setUpIndividual/:entityType"
        element={<IndividualNextStep />}
      />
      <Route path="/setUpIndividualFinish" element={<IndividualFinishStep />} />
      <Route
        path="/setUpDeposit"
        element={<DepositSettings fromSetup={true} />}
      />
      <Route
        path="/setUpGroupNextStep/:entityType"
        element={<GroupPracticeNextStep />}
      />
      <Route path="/AcceptDeligance" element={<DelliganceAcceptance />} />

      <Route
        path="/setUpGroupFinishStep"
        element={<GroupPracticeFinishStep />}
      />
      <Route path="/joinAnExistingGroup" element={<ExistingGroupPractices />} />

      <Route path="/joinAnExistingGroup" element={<ExistingGroupPractices />} />

      {/* <Route path="/login" element={<AuthGuard />}>
        <Route index element={<Login />} />
      </Route> */}
      <Route path="/login" element={<ExternalLoginGuard />}>
        <Route index element={<Login />} />
      </Route>
      {/* Embedded stripe checkout */}
      <Route path="/checkout" element={<EmbeddedStripeCheckout />} />
      <Route path="/return" element={<EmbeddedStripeReturn />} />

      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route
        path="/admin/forgetPassword"
        element={<ForgetPassword isAdmin />}
      />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/admin/resetPassword" element={<ResetPassword isAdmin />} />
      <Route path="/joinEntity" element={<JoinEntity />} />
      <Route path="/registration" element={<SignUp />} />
      <Route path="/ViewInvitation" element={<ViewInvitation />} />
      <Route path="/:admin/ViewInvitation" element={<ViewInvitation />} />
      <Route path="/declineInvitation" element={<DeclineInvitation />} />
      <Route path="/declinedInvitation" element={<DeclinedInvitation />} />
      <Route path="/acceptInvitation" element={<AcceptInvitation />} />
      <Route path="/order-accepted" element={<OrderAccepted />} />
      <Route path="/order-denied" element={<OrderDenied />} />
      <Route path="/expired-link" element={<ExpiredLink />} />
      <Route
        path="/complete-provider-data"
        element={<CompleteProviderVerification />}
      />
      <Route
        path="/verified-success"
        element={<CompleteVerificationSuccess />}
      />
      <Route
        path="/order-already-Accepted"
        element={<ProviderAlreadyAccepted />}
      />
      <Route path="/order-confirmation" element={<ManageOrderFromProvider />} />
      <Route path="/order-already-denied" element={<ProviderAlreadyDenied />} />

      {/* start admin */}
      <Route path="/admin/login" element={<AdminLoginAuthGaurd />}>
        <Route index element={<AdminLogin />} />
      </Route>

      <Route element={<PersonateAuthGaurd />}>
        <Route
          path="/chooseEntity/"
          element={
            isCompletedRegister == "False" ? (
              <Navigate to="/completeRegRedirect" replace={false} />
            ) : (
              <ChooseEntity />
            )
          }
        />
        <Route
          path="/admin/registration"
          element={
            isAdminRegistrationRoute ? (
              <AdminRegistration />
            ) : (
              <Navigate
                to={
                  location.pathname.includes("admin")
                    ? "/admin/login"
                    : "/login"
                }
              />
            )
          }
        />
      </Route>
      {/* end admin */}
      <Route element={<ProtectedRoute />}>
        {/* start admin */}
        <Route element={<AdminAuthGaurd />}>
          <Route path="/admin/Dashboard" element={<AdminDashboard />} />
          <Route path="/admin/worklists" element={<WorkLists />} />
          <Route path="/admin/worklists/provider/:id" element={<Provider />} />
          <Route path="/admin/ourPractices" element={<OurPractices />} />
          <Route
            path="/admin/settings/passwordSetting"
            element={<ChangePassword />}
          />
          <Route
            path="/admin/settings/environment"
            element={<EnvironmentSettings />}
          />
          <Route
            path="/admin/settings/notificationsSettings"
            element={<SystemAdminNotifs />}
          />
          <Route
            path="/admin/transactionTracking"
            element={<RenderTransactionTracking />}
          />
          <Route
            path="/admin/settings/admin-information"
            element={<AdminGlobalInfo />}
          />
          <Route
            path="/admin/worklists/complete/:id"
            element={<RemitsComplete />}
          />
          <Route path="/admin/ourPractices/:id" element={<PracticeDetails />} />
          <Route path="/admin/manageAdmins" element={<ManageAdmins />} />
        </Route>
        {/* end admin */}
        <Route element={<PersonateAuthGaurd />}>
          {/*start render view  */}
          <Route path="/Dashboard/" element={<DashboardRendering />} />
          <Route element={<UserRoleGuard />}>
            <Route path="/rendering/staff" element={<StaffDashboard />} />
            <Route
              path="/rendering/mylocations/locationForm"
              element={<LocationForm />}
            />
            <Route
              path="/rendering/settings/individualSetting"
              element={<EditIndividualPractice />}
            />
            <Route
              path="/rendering/settings/DepositSettings"
              element={<DepositSettings />}
            />
            <Route
              path="/service/MyServices"
              element={<ManageCreatedServices />}
            />
            <Route path="/test-service/:serviceId" element={<TestService />} />
            <Route
              path="/Service/MyServices/createNewService"
              element={<CreateNewService />}
            />
            <Route
              path="/service/ManageServices"
              element={<ManageServices />}
            />
            <Route
              path="/service/SingleQuestionForm"
              element={<SingleQuestionForm />}
            />
            <Route
              path="/service/CreateServiceTemplate/:serviceId/:isTemplate"
              element={<CreateServiceTemplate />}
            />
            <Route
              path="/Service/MyServices/EditService/:serviceId/:isTemplate"
              element={<CreateServiceTemplate isEditing={true} />}
            />
            <Route
              path="/service/CreateBillableItem"
              element={<BillableItem />}
            />
            <Route path="/QuestionTemplateMaker" element={<CreateQuestion />} />
            <Route
              path="/Service/MyServices/createNewServiceTemplate"
              element={<CreateServiceTemplate isEditing={false} />}
            />
            <Route path="/Service/billableItems" element={<BillableItem />} />
            <Route
              path="/Service/billableItems/report"
              element={<BillableItemsReport />}
            />
            <Route path="/Service/documents" element={<DocumentsLibrary />} />
            <Route path="/rendering/Transactions" element={<Transactions />} />
          </Route>
          <Route path="/rendering/mylocations" element={<LocationsExplore />} />
          <Route path="/rendering/Settings" element={<Settings />} />
          <Route
            path="/rendering/settings/privacy"
            element={<PrivacyPreferences />}
          />
          <Route
            path="/rendering/settings/ProfileSettings"
            element={<ProfileSettings />}
          />
          <Route
            path="/rendering/settings/security/changePassword"
            element={<ChangePassword />}
          />
          <Route
            path="/rendering/settings/security/managePin"
            element={<DialogUpdatePin />}
          />
          <Route path="/rendering/settings/security" element={<Security />} />
          <Route
            path="/rendering/settings/groupSettings"
            element={<EditGroupPractice />}
          />
          <Route
            path="/rendering/settings/OrderSettings/"
            element={<OrderSettings />}
          />
          <Route
            path="/rendering/settings/userNotifications"
            element={<UserNotificationSettings />}
          />
          <Route
            path="/rendering/settings/practiceNotifications"
            element={<PracticeNotificationSettings />}
          />
          <Route path="/rendering/Orders" element={<OrderingProvider />} />
          <Route path="/rendering/Orders/:orderId" element={<OrderDetails />} />
          {/* end render view  */}
          {/* start order view  */}
          <Route element={<UserRoleGuard />}>
            <Route path="/ordering/staff" element={<StaffDashboard />} />
            <Route
              path="/ordering/settings/individualSetting"
              element={<EditIndividualPractice />}
            />
          </Route>
          <Route element={<IsHiddenGuard />}>
            <Route path="/ordering/FindServices" element={<FindServices />} />
            <Route path="/ordering/practices" element={<FavoritePractices />} />
          </Route>
          <Route
            path="/ordering/practices/:practiceId"
            element={<EntityPage />}
          />
          <Route path="/ordering/Settings" element={<Settings />} />
          <Route
            path="/ordering/settings/privacy"
            element={<PrivacyPreferences />}
          />
          <Route
            path="/ordering/settings/ProfileSettings"
            element={<ProfileSettings />}
          />
          <Route
            path="/ordering/settings/security/changePassword"
            element={<ChangePassword />}
          />
          <Route path="/ordering/settings/security" element={<Security />} />
          <Route path="/ordering/Orders/" element={<OrderingOrders />} />
          <Route
            path="/ordering/settings/groupSettings"
            element={<EditGroupPractice />}
          />
          <Route
            path="/ordering/settings/ProfileSettings"
            element={<ProfileSettings />}
          />
          <Route
            path="/ordering/settings/verifyProvider"
            element={<VerifyProvider />}
          />
          <Route
            path="/ordering/settings/userNotifications"
            element={<UserNotificationSettings />}
          />
          <Route
            path="/ordering/settings/practiceNotifications"
            element={<PracticeNotificationSettings />}
          />
          {/* end order vie  */}
        </Route>
        <Route path="/orderService" element={<OrderService />} />
        <Route
          path="/ordering/RequestService/:serviceId"
          element={<OrderService />}
        />
      </Route>
      <Route path="/legal/tos" element={<TermsAndConditions />} />
      <Route path="/legal/privacy" element={<PrivacyPolicy />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/EmailOrderUpdate" element={<EmailOrderUpdate />} />
      <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
      <Route path="/CaptureViaMobile" element={<CaptureViaMobile />} />
      <Route path="/seq" element={<Seq />} />
      {/* <Route path="/" element={<Navigate to="/login" />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
