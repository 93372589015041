import React, { useRef, useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";

const HeightInput = ({ heightValue, onHeightChange, disabled = false }) => {
  const heightData = useRef({ ft: 0, in: 0 });
  const [feetValue, setFeetValue] = useState(null);
  const [inchesValue, setInchesValue] = useState(null);

  useEffect(() => {
    if (heightValue !== null && heightValue !== undefined) {
      const ft = Math.floor(heightValue);
      const inches = Math.round((heightValue - ft) * 12);
      heightData.current = { ft, in: inches };
      setFeetValue(ft);
      setInchesValue(inches);
    }
  }, [heightValue]);

  const handleFeetChange = (e) => {
    heightData.current.ft = e.value;
    onHeightChange(+e.value + +heightData.current.in / 12);
  };

  const handleInchesChange = (e) => {
    heightData.current.in = e.value;
    onHeightChange(+heightData.current.ft + +e.value / 12);
  };

  return (
    <div className="flex gap-2">
      <div className="flex gap-1">
        <InputNumber
          useGrouping={false}
          className="!w-12"
          inputClassName="!w-12"
          keyfilter={/^\d+$/}
          max={9}
          min={0}
          value={feetValue}
          onChange={handleFeetChange}
          onKeyDown={(e) => {
            const currentValue = e.target.value;
            const isHighlighting =
              e.target.selectionStart !== e.target.selectionEnd;
            const isZero = currentValue === "0";

            if (!isHighlighting) {
              // Prevent input if the current value is 0 and the user is trying to add another digit
              if (isZero && /^[0-9]$/.test(e.key)) {
                e.preventDefault();
              }

              const newValue = Number(currentValue + e.key);
              if (newValue > 9) e.preventDefault();
            }
          }}
          disabled={disabled}
          placeholder="ft."
        />
        <span className="text-2xl font-semibold">'</span>
      </div>

      <div className="flex gap-1">
        <InputNumber
          useGrouping={false}
          className="!w-12"
          inputClassName="!w-12"
          keyfilter={/^\d+$/}
          max={11}
          min={0}
          value={inchesValue}
          onChange={handleInchesChange}
          onKeyDown={(e) => {
            const currentValue = e.target.value;
            const isHighlighting =
              e.target.selectionStart !== e.target.selectionEnd;
            const isZero = currentValue === "0";

            if (!isHighlighting) {
              // Prevent input if the current value is 0 and the user is trying to add another digit
              if (isZero && /^[0-9]$/.test(e.key)) {
                e.preventDefault();
              }

              const newValue = Number(currentValue + e.key);
              if (newValue > 11) e.preventDefault();
            }
          }}
          disabled={disabled}
          placeholder="in."
        />
        <span className="text-2xl font-semibold">"</span>
      </div>
    </div>
  );
};

export default HeightInput;
