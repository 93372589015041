import React, { useEffect, useRef, useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Editor } from "primereact/editor";
import { Tooltip } from "primereact/tooltip";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { OverlayPanel } from "primereact/overlaypanel";
import { TabPanel, TabView } from "primereact/tabview";
import useAxios from "../../hooks/useAxios";
import RenderedQuestion from "../RenderedQuestion";
import useTokenData from "../../hooks/useTokenData";
import { editorHeaderTemplate } from "../utility/Utils";
import useHandleResize from "../../hooks/useHandleResize";
import HeightInput from "./HeightInput";

const QuestionMaker = ({
  startRule,
  editQuestion = false,
  setEditQuestion,
  questionType = "",
  formQuestions,
  setFormQuestions,
  onAddToForm,
  setVisible,
  initialQuestionState,
  questionToEdit,
  allRules,
  createNewLast,
  defaultAnswer = [
    {
      label: "Yes",
    },
    {
      label: "No",
    },
  ],
  setAllRules,
  createNew = false,
  setIsEditingRule,
  currentIndex,
  setQuestionToEdit,
  afterFinishFunction,
  setCreateNew,
}) => {
  const overlaypanelRef = useRef(null);
  const { entityId } = useTokenData();
  const [question, setQuestion] = useState({
    type: questionType,
    label: "",
    description: "",
    options:
      questionType === "yesNo"
        ? [
            {
              label: "Yes",
            },
            {
              label: "No",
            },
          ]
        : defaultAnswer,
    show: false,
    conditions: [
      {
        question: {
          label: "",
        },
        answer: {
          label: "",
        },
        relation: "equal",
        answerOptions: [],
        numberComparisionCondition: "equal",
        numberComparision: 0,
        numberComparisionRange: { min: 0, max: 0 },
      },
    ],
    required: true,
    action: "and",
  });

  const { http } = useAxios();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuestion((prevData) => ({
      ...prevData,
      [name]: value,
      conditions: [...prevData.conditions],
    }));
  };

  const handleEditorChange = (newContent) => {
    setQuestion((prevData) => ({
      ...prevData,
      description: newContent,
    }));
  };

  const handleConditionChange = (e) => {
    const { name, value } = e.target;
    setQuestion((prevQuestion) => {
      return {
        ...prevQuestion,
        [name]: value,
        conditions:
          name === "show"
            ? [
                {
                  question: {
                    label: "",
                    options: [],
                  },
                  answer: {
                    label: "",
                  },
                  relation: "equal",
                  answerOptions: [],
                  numberComparisionCondition: "equal",
                  numberComparision: 0,
                  numberComparisionRange: { min: 0, max: 0 },
                },
              ]
            : [...prevQuestion.conditions],
      };
    });
  };

  const handleAddOption = () => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      options: [...prevQuestion.options, { label: "" }],
    }));
  };

  const handleRemoveOption = (index) => {
    setQuestion((prevQuestion) => {
      const updatedOptions = [...prevQuestion.options];
      updatedOptions.splice(index, 1);
      return { ...prevQuestion, options: updatedOptions };
    });
  };

  const handleCancelEditingQuestion = () => {
    setQuestion({
      type: "dropDown",
      label: "",
      description: "",
      options: [
        {
          label: "",
        },
        {
          label: "",
        },
      ],
      show: false,
      conditions: [
        {
          question: {
            label: "",
          },
          answer: {
            label: "",
          },
          relation: "equal",
          answerOptions: [],
          numberComparisionCondition: "equal",
          numberComparision: 0,
          numberComparisionRange: { min: 0, max: 0 },
        },
      ],
      required: true,
      action: "and",
    });

    setEditQuestion(false);
    setQuestionToEdit();
    setQuestionErrors({})
  };

  const replaceQuestionInConditions = (
    questions,
    editedQuestionIndex,
    editedQuestionLabel,
    newQuestion,
  ) => {
    return questions.map((question, _index) => {
      if (_index === editedQuestionIndex || _index <= 1) return question;
      const updatedConditions = question.conditions.map((condition) => {
        const questionLabel =
          condition?.question?.label?.label || condition?.question?.label;
        if (questionLabel === editedQuestionLabel) {
          const newQ = {
            ...condition,
            question: newQuestion,

            answerOptions: [...newQuestion.options],
          };

          return newQ;
        }
        return condition;
      });

      return { ...question, conditions: updatedConditions };
    });
  };

  const replaceQuestionInRules = (rules, editedQuestionLabel, newQuestion) => {
    return rules.map((rule) => {
      let updatedConditions = [];
      if (rule.conditions) {
        updatedConditions = rule.conditions.map((condition) => {
          const questionLabel =
            condition?.question?.label?.label || condition?.question?.label;
          if (questionLabel === editedQuestionLabel) {
            return {
              ...condition,
              question: newQuestion,
              answerOptions: [...newQuestion.options],
            };
          }
          return condition;
        });
      }

      const updatedNestedRules = rule.rules
        ? replaceQuestionInRules(rule.rules, editedQuestionLabel, newQuestion)
        : rule.rules;

      return {
        ...rule,
        conditions: updatedConditions,
        rules: updatedNestedRules,
      };
    });
  };

  const handleEditQuestion = () => {
    // Validation
    if (!validateInputs()) {
      return;
    } else {
      const editedQuestionIndex = questionToEdit.index;
      const editedQuestionLabel = formQuestions[editedQuestionIndex].label;
      const updatedFormQuestions = replaceQuestionInConditions(
        formQuestions,
        editedQuestionIndex,
        editedQuestionLabel,
        question,
      );
      const updatedAllRules = replaceQuestionInRules(
        allRules,
        editedQuestionLabel,
        question,
      );

      updatedFormQuestions[editedQuestionIndex] = {
        ...question,
        options: question.options.filter((item) => item.label.trim()),
      };

      updatedFormQuestions[questionToEdit.index].options = updatedFormQuestions[
        questionToEdit.index
      ].options.filter((item) => item.label.trim());

      setFormQuestions(updatedFormQuestions);
      setAllRules(updatedAllRules);
      setQuestionToEdit();

      if (isQuestionInUse(questionToEdit?.index)) {
        confirmDialog({
          message:
            "You've just edited a question that's linked to another question or rule. Please check your rules to make sure everything stays consistent.",
          header: "Review Your Linked Rules",
          icon: "pi pi-exclamation-triangle",
          defaultFocus: "accept",
          acceptLabel: "OK",
          accept: () => {},
          style: { width: "800px" },
          draggable: false,
          rejectClassName: "hidden",
        });
      }
    }
    
    setEditQuestion(false);
  };

  const [questionErrors, setQuestionErrors] = useState({});

  function hasDuplicateLabels(array) {
    const labelSet = new Set();

    for (const item of array) {
      const { label } = item;

      // Skip empty labels
      if (!label.trim()) {
        continue;
      }

      if (labelSet.has(label.trim())) {
        return true; // Found a duplicate label
      }
      labelSet.add(label.trim());
    }
    return false; // No duplicate labels found
  }

  const handleEditQuestionInForm = (editedQuestion, index) => {
    setFormQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index] = editedQuestion;
      return updatedQuestions;
    });
  };

  const handleRemoveQuestion = (index) => {
    setFormQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(index, 1);
      return updatedQuestions;
    });
  };

  const propertyExists = () =>
    formQuestions.some(
      (obj) =>
        obj.hasOwnProperty("label") &&
        obj.label.trim() === question.label?.trim(),
    );

  const validateInputs = () => {
    let noErrors = true;
    let errors = {};
    // Initialize a counter for non-empty labels
    let nonEmptyLabelCount = 0;
    // Iterate through the array
    for (let i = 0; i < question.options.length; i++) {
      // Check if the label is not empty
      if (question.options[i].label.trim()) {
        // Increment the non-empty label counter
        nonEmptyLabelCount++;
        // If we have found at least two non-empty labels, we can stop iterating
      }
    }

    if (
      nonEmptyLabelCount < 2 &&
      !["number", "decimal"].includes(question.type)
    ) {
      errors.multiChoice = "Please add at least two answers";
      noErrors = false;
    }
    if (!question.label.trim()) {
      errors.label = "Question label is required";
      noErrors = false;
    }
    if (!question.type.trim()) {
      errors.type = "Question type is required";
      noErrors = false;
    }
    if (!editQuestion) {
      if (propertyExists()) {
        errors.repeated = "Please enter a unique question label";
        noErrors = false;
      }
    }
    if (
      (question.type === "multipleChoice" || question.type === "dropDown") &&
      (!question.options ||
        question.options.length < 2 ||
        !nonEmptyLabelCount >= 2)
    ) {
      errors.multiChoice =
        "Please add at least two non-empty options for the question";
      noErrors = false;
    }

    if (hasDuplicateLabels(question.options)) {
      errors.multiChoice =
        "Duplicate answers detected. Please ensure all answer options are unique";
      noErrors = false;
    }

    if (question.show) {
      question.conditions.forEach((condition, index) => {
        if (
          !(
            (condition.question && condition.question.label)
            // condition.question.label.label &&
            // condition.question.label.label.trim()
          )
        ) {
          errors[`conditionLabel${index}`] = `question ${
            index + 1
          } is required.`;
          noErrors = false;
        }
      });
    }

    if (question.show) {
      question.conditions.forEach((condition, index) => {
        if (
          condition.question &&
          !["number", "decimal"].includes(condition.question.type)
        ) {
          if (
            !(
              condition.answer &&
              condition.answer.label &&
              condition.answer.label.label &&
              condition.answer.label.label.trim()
            )
          ) {
            errors[`answerLabel${index}`] = `answer  ${index + 1} is required.`;
            noErrors = false;
          }
        } else {
          if (
            ["number", "decimal"].includes(condition.question.type) &&
            (!condition.numberComparisionCondition ||
              condition.numberComparision === null ||
              (condition.numberComparisionCondition === "between" &&
                (!condition.numberComparisionRange ||
                  condition.numberComparisionRange.min === null ||
                  condition.numberComparisionRange.max === null)))
          ) {
            errors[`answerLabel${index}`] = `answer  ${index + 1} is required.`;
            noErrors = false;
          }
        }
      });
    }

    if (question.show) {
      // if (!question.hasOwnProperty("questionCondtion")) {
      //   errors.questionCondtion = "Question condition is required";
      //   noErrors = false;
      // }

      if (!question.hasOwnProperty("action")) {
        errors.action = "Action is required";
        noErrors = false;
      }
    }

    if (
      question?.constraints &&
      Object.keys(question?.constraints).includes("min") &&
      !question?.constraints?.min
    ) {
      errors.constraints = "Minimum value cannot be empty";
      noErrors = false;
    } else if (
      question?.constraints &&
      Object.keys(question?.constraints).includes("max") &&
      !question?.constraints?.max
    ) {
      errors.constraints = "Maximum value cannot be empty";
      noErrors = false;
    } else if (
      question?.constraints &&
      Object.keys(question?.constraints).includes("min") &&
      Object.keys(question?.constraints).includes("max") &&
      +question.constraints.min > +question.constraints.max
    ) {
      errors.constraints =
        "The minimum value cannot be greater than the maximum value";
      noErrors = false;
    }

    setQuestionErrors(errors);
    return noErrors;
  };

  const addQuestion = () => {
    const options = question.options.filter((item) => item.label.trim());
    setQuestion({ ...question, options: options });
    onAddToForm({ ...question, options: options });
    setQuestion({
      type: "",
      label: "",
      description: "",
      options: [
        {
          label: "",
        },
        {
          label: "",
        },
      ],
      action: "and",
      show: false,
      conditions: [
        {
          question: {
            label: "",
          },
          answer: {
            label: "",
          },
          relation: "equal",
          answerOptions: [],
          numberComparisionCondition: "equal",
          numberComparision: 0,
          numberComparisionRange: { min: 0, max: 0 },
        },
      ],
      required: true,
    });
  };

  const handleSaveQuestion = () => {
    if (!validateInputs()) {
      return;
    } else {
      startRule();
      addQuestion();
      afterFinishFunction();
    } // If validation passes, proceed to save the question
  };

  const questionTypes = [
    { label: "Multiple Choice (Checkbox)", value: "multipleChoice" },
    { label: "Single Choice (Dropdown)", value: "dropDown" },
    { label: "Number (whole)", value: "number" },
    { label: "Number (decimal)", value: "decimal" },
    { label: "Yes or No", value: "yesNo" },
  ];

  const questionOperators = [
    { label: "Equal", value: "equal" },
    { label: "Not Equal", value: "notEqual" },
  ];

  const ruleOperators = [
    { label: "All", value: "and" },
    { label: "Any", value: "or" },
  ];

  const handleAddCondition = () => {
    const newState = {
      ...question,
      conditions: [
        ...question.conditions,
        {
          question: {
            label: "",
          },
          answer: {
            label: "",
          },
          relation: "equal",
          answerOptions: [],
          numberComparisionCondition: "equal",
          numberComparision: 0,
          numberComparisionRange: { min: 0, max: 0 },
        },
      ],
    };
    setQuestion(newState);
  };
  // Function to delete an object from the conditions array
  const deleteObject = (indexToDelete) => {
    const newConditions = question.conditions.filter(
      (_, index) => index !== indexToDelete,
    );
    setQuestion({
      ...question,
      conditions: newConditions,
    });
  };

  const [openCreateQuestionDialog, SetOpenCreateQuestionDialog] =
    useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [listOfQuestion, setListOfQuestion] = useState();
  const getListOfQuestion = async () => {
    const response = await http.get(
      `Questions/GetAllQuestions/${entityId}?Filters=IsEnabled==true`,
    );
    setListOfQuestion(response.data);
  };

  useEffect(() => {
    if (openCreateQuestionDialog === true) {
      getListOfQuestion();
    }
  }, [openCreateQuestionDialog]);

  useEffect(() => {
    setQuestion({
      ...question,
      constraints: {
        ...question.constraints,
        type: "regular",
      },
    });
  }, []);

  const handleApplyQuestion = () => {
    if (selectedQuestion) {
      try {
        const parsedQuestion = JSON.parse(selectedQuestion.question);
        // Update the form fields with the selected question data
        setQuestion({
          ...question,
          label: parsedQuestion.label,
          description: parsedQuestion.description,
          type: parsedQuestion.type,
          options: parsedQuestion.options,
          // Update other relevant fields as needed
        });
        // Close the dialog
        SetOpenCreateQuestionDialog(false);
      } catch (error) {
        console.error("Error parsing selected question:", error);
      }
    }
  };

  const handleCancel = () => {
    setCreateNew(false);
  };
  const errorKeys = Object.keys(questionErrors);
  const questionDetailsErrors = ["multiChoice", "label", "type", "repeated"];
  const PulsingCircle = () => (
    <span className="relative ml-2 flex h-3 w-3">
      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75"></span>
      <span className="relative inline-flex h-3 w-3 rounded-full bg-red-500"></span>
    </span>
  );

  const { isMobile } = useHandleResize();

  const isQuestionInRules = (rules, label) => {
    return rules?.some((rule) => {
      // Check conditions in the current rule
      const isInCurrentRule = rule?.conditions?.some((condition) => {
        const questionLabel =
          condition?.question?.label?.label || condition?.question?.label;
        return questionLabel === label;
      });

      // Recursively check nested rules
      const isInNestedRules = rule?.rules
        ? isQuestionInRules(rule.rules, label)
        : false;

      return isInCurrentRule || isInNestedRules;
    });
  };

  const isQuestionInUse = (index) => {
    if (!index) return false;
    const label = formQuestions[index].label;
    if (!label) return false;
    const isQuestionInConditions = formQuestions?.some((question, _index) => {
      if (_index === index) return false;
      if (_index <= 1) return false;
      return question?.conditions?.some((condition) => {
        const questionLabel =
          condition?.question?.label?.label || condition?.question?.label;
        return questionLabel === label;
      });
    });

    const isQuestionInAllRules = isQuestionInRules(allRules, label);

    return isQuestionInConditions || isQuestionInAllRules;
  };

  const [selectedNumericType, setSelectedNumericType] = useState("regular");

  const numberTypes = [
    { label: "Regular", value: "regular" },
    { label: "U.S. Height", value: "height" },
  ];

  const heightData = useRef({ minFt: 0, maxFt: 0, minIn: 0, maxIn: 0 });

  return (
    <>
      <Tooltip target=".custom-question-tooltip" />
      <OverlayPanel ref={overlaypanelRef}>
        <span className="text-red-500">Answer label is required</span>
      </OverlayPanel>
      <Dialog draggable={false} blockScroll></Dialog>
      {createNew || questionToEdit?.index == currentIndex ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="p-fluid text-pretty rounded border bg-white md:p-5"
        >
          <TabView>
            <TabPanel
              rightIcon={
                errorKeys.some((errKey) =>
                  questionDetailsErrors.includes(errKey),
                ) ? (
                  <PulsingCircle />
                ) : undefined
              }
              header="Question Details"
            >
              <h2 className="pb-4 text-xl font-semibold capitalize text-[#3D3877]">
                {editQuestion ? "Edit question" : "Add new question"}
              </h2>
              <p className="mb-5 w-full max-w-[80%] text-base text-gray-700">
                Below is the section where you configure if there's any
                questions to ask your ordering providers that may change how the
                service is rendered
              </p>
              <div className="p rounded-lg border-gray-100 md:border md:p-6 md:shadow-lg">
                <div>
                  <div className="flex w-full flex-col gap-2 lg:grid lg:grid-cols-6">
                    <div className="p-field col-span-4 flex flex-col gap-2">
                      <label htmlFor="label">Write Your Question:</label>
                      <InputText
                        className="w-full rounded-none"
                        id="label"
                        name="label"
                        value={question.label}
                        onChange={(e) => {
                          delete questionErrors.label;
                          handleInputChange({
                            target: { name: "label", value: e.target.value },
                          });
                        }}
                      />
                      {questionErrors?.label && (
                        <p className="text-red-500">{questionErrors.label}</p>
                      )}
                      {questionErrors?.repeated && (
                        <p className="text-red-500">
                          {questionErrors.repeated}
                        </p>
                      )}
                    </div>{" "}
                    <div className="p-field col-span-2 flex flex-col gap-2">
                      <label htmlFor="type">Choose Question Type:</label>
                      <Dropdown
                        id="type"
                        name="type"
                        value={question.type}
                        options={questionTypes}
                        disabled={isQuestionInUse(questionToEdit?.index)}
                        onChange={(e) => {
                          delete questionErrors.type;
                          delete questionErrors.constraints;
                          let options;
                          let constraints = question?.constraints;
                          options =
                            e.value == "yesNo"
                              ? [
                                  {
                                    label: "Yes",
                                  },
                                  {
                                    label: "No",
                                  },
                                ]
                              : [
                                  {
                                    label: "",
                                  },
                                  {
                                    label: "",
                                  },
                                ];
                          if (
                            (question.type === "multipleChoice" &&
                              e.value === "dropDown") ||
                            (question.type === "dropDown" &&
                              e.value === "multipleChoice")
                          ) {
                            options = question.options;
                          }
                          if (!["number", "decimal"].includes(e.value)) {
                            constraints = {};
                          }
                          setQuestion({
                            ...question,
                            type: e.value,
                            options: options,
                            constraints,
                          });
                          if (
                            e.value === "yesNo" ||
                            ["number", "decimal"].includes(e.value)
                          ) {
                            delete questionErrors.multiChoice;
                          }
                        }}
                        placeholder="Select Type"
                        optionLabel="label"
                        className="rounded-none"
                        // pt={{ input: { className: " px-3" } }}
                      />
                      {questionErrors?.type && (
                        <p className="text-red-500">{questionErrors.type}</p>
                      )}
                    </div>
                  </div>
                  <div className="py-2">
                    <Checkbox
                      className="pb-5"
                      inputId="isRequired"
                      name="isRequired"
                      checked={question.required}
                      onChange={(e) =>
                        setQuestion((q) => ({
                          ...q,
                          required: !question.required,
                        }))
                      }
                    />
                    <label htmlFor="isRequired" className="cursor-pointer pl-2">
                      Require an Answer to this question
                    </label>
                  </div>
                </div>
                <Divider />
                {question.type === "multipleChoice" ||
                question.type === "dropDown" ? (
                  <div className="mt-2">
                    <label className="mb-3">Answer Options:</label>
                    {question.options.map((option, index) => (
                      <div key={index}>
                        <div className="my-4 grid grid-cols-12 items-center">
                          <span className="col-span-10">
                            <InputText
                              className="rounded-none"
                              type="text"
                              value={option.label}
                              disabled={
                                isQuestionInUse(questionToEdit?.index) &&
                                index < questionToEdit.question.options.length
                              }
                              onChange={(e) => {
                                var updatedOptions = [...question.options];
                                updatedOptions[index] = {
                                  ...question.options[index],
                                  label: e.target.value,
                                };
                                // *** validate all inputs *** //
                                if (
                                  questionErrors?.multiChoice &&
                                  updatedOptions.every((option) =>
                                    option.label.trim(),
                                  )
                                ) {
                                  delete questionErrors.multiChoice;
                                }
                                // *************************** //

                                setQuestion((prevQuestion) => ({
                                  ...prevQuestion,
                                  options: updatedOptions,
                                }));
                              }}
                            />{" "}
                          </span>{" "}
                          {questionToEdit &&
                          isQuestionInUse(questionToEdit?.index) ? (
                            <span className="col-span-2 flex">
                              {index >=
                              questionToEdit.question.options.length ? (
                                <i
                                  type="button"
                                  label="Remove Option"
                                  onClick={() => {
                                    handleRemoveOption(index);
                                  }}
                                  className="pi pi-minus-circle p-button-danger ml-2 cursor-pointer text-red-400"
                                />
                              ) : null}
                              {index === question.options.length - 1 ? (
                                <i
                                  label="Add another answer"
                                  type="button"
                                  onClick={handleAddOption}
                                  tooltip="Add another answer"
                                  className="p-button-success pi pi-plus-circle ml-2 cursor-pointer text-green-400"
                                  data-pr-tooltip="Add New Option "
                                  data-pr-position="top"
                                />
                              ) : null}
                            </span>
                          ) : (
                            <span className="col-span-2 flex">
                              {question.options.length > 2 ? (
                                <i
                                  type="button"
                                  label="Remove Option"
                                  onClick={() => {
                                    handleRemoveOption(index);
                                  }}
                                  className="pi pi-minus-circle p-button-danger ml-2 cursor-pointer text-red-400"
                                />
                              ) : null}
                              {index === question.options.length - 1 ? (
                                <i
                                  label="Add another answer"
                                  type="button"
                                  onClick={handleAddOption}
                                  tooltip="Add another answer"
                                  className="p-button-success pi pi-plus-circle ml-2 cursor-pointer text-green-400"
                                  data-pr-tooltip="Add New Option "
                                  data-pr-position="top"
                                />
                              ) : null}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                    {questionErrors?.multiChoice && (
                      <p className="text-red-500">
                        {questionErrors.multiChoice}
                      </p>
                    )}
                  </div>
                ) : question.type === "yesNo" ? (
                  <div className="p-2">
                    <label className="mb-3">Answer Options:</label>

                    <div className="mt-2">
                      <RadioButton className="mr-1" disabled /> Yes
                    </div>

                    <div className="mt-2">
                      <RadioButton className="mr-1" disabled /> No
                    </div>
                  </div>
                ) : null}

                <div className="card mt-3 flex flex-col gap-2">
                  <label>Question Description</label>
                  <Editor
                    value={
                      question.description?.htmlValue
                        ? question.description?.htmlValue
                        : null
                    }
                    onTextChange={handleEditorChange}
                    style={{
                      height: "164px",
                    }}
                    headerTemplate={editorHeaderTemplate}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel
              header="Question Options"
              rightIcon={
                errorKeys?.some(
                  (element) =>
                    element.startsWith("answerLabel") ||
                    element.startsWith("conditionLabel") ||
                    element.startsWith("constraints"),
                ) ? (
                  <PulsingCircle />
                ) : undefined
              }
            >
              {formQuestions.length ? (
                <div className="p-field">
                  <Tooltip target=".custom-question-tooltip" />
                  <label className="text-gray-600">Conditional Settings</label>
                  <span className="pl-2 text-gray-500">
                    <i
                      className="custom-question-tooltip pi pi-info-circle text-xl"
                      data-pr-tooltip="This question will be displayed based on specific answer from previous question that you select"
                      data-pr-position="right"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                  <div className="my-4 rounded-lg border-slate-200 md:border md:px-6 md:py-4">
                    <div className="flex flex-col gap-2 py-2 md:flex-row md:items-center md:gap-0">
                      <div>
                        <Checkbox
                          inputId="showCondition"
                          name="showCondition"
                          checked={question.show}
                          className="mb-1"
                          onChange={(e) =>
                            handleConditionChange({
                              target: { name: "show", value: e.checked },
                            })
                          }
                        />
                        <label
                          htmlFor="showCondition"
                          className="cursor-pointer pl-2"
                        >
                          Only show this question if &nbsp;
                        </label>
                      </div>
                      <Dropdown
                        className="w-full rounded-none md:w-28"
                        options={ruleOperators}
                        value={question.action}
                        disabled={!question.show}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setQuestion((prevQuestion) => ({
                            ...prevQuestion,
                            action: selectedValue,
                          }));

                          setQuestionErrors((prevErrors) => {
                            const newErrors = { ...prevErrors };
                            if (selectedValue) {
                              delete newErrors["action"];
                            } else {
                              newErrors["action"] = "Action  is required.";
                            }
                            return newErrors;
                          });
                        }}
                      />
                      &nbsp; of these questions matched
                    </div>
                    <div className="flex items-center gap-5">
                      <div>
                        {questionErrors && questionErrors.action && (
                          <p className="text-red-500">
                            {questionErrors.action}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="p-0 md:p-2">
                      <label htmlFor="questionNumber" className="text-gray-700">
                        Questions
                      </label>
                      {question.conditions.map((conditionData, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="py-2" key={index}>
                              <div className="flex flex-col gap-3 md:grid md:grid-cols-11 md:items-center">
                                <Dropdown
                                  value={
                                    typeof conditionData?.question?.label ===
                                    "string"
                                      ? conditionData?.question
                                      : conditionData?.question?.label
                                  }
                                  placeholder={"Select question"}
                                  disabled={!question.show}
                                  optionLabel="label"
                                  options={formQuestions.filter(
                                    (formQuestion, formIndex) =>
                                      currentIndex !== formIndex &&
                                      !formQuestion.conditions.some(
                                        (condition) =>
                                          condition?.question?.label ===
                                          formQuestions[currentIndex]?.label,
                                      ),
                                  )}
                                  // optionValue="label"
                                  className="col-span-4 rounded-none md:col-span-3"
                                  onChange={(e) => {
                                    const updatedConditions = [
                                      ...question.conditions,
                                    ];
                                    updatedConditions[index].question =
                                      e.target.value;
                                    updatedConditions[index].question.type =
                                      e.target.value.type;
                                    updatedConditions[index].answerOptions =
                                      e.target.value.options;

                                    setQuestion({
                                      ...question,
                                      conditions: updatedConditions,
                                    });
                                    if (
                                      ["number", "decimal"].includes(
                                        e.target.value.type,
                                      )
                                    ) {
                                      const updatedConditions = [
                                        ...question.conditions,
                                      ];
                                      updatedConditions[index].relation =
                                        "equal";
                                      setQuestion({
                                        ...question,
                                        conditions: updatedConditions,
                                      });
                                    }
                                    // Remove the error message when the value changes
                                    setQuestionErrors((prevErrors) => {
                                      const newErrors = { ...prevErrors };
                                      delete newErrors[
                                        `conditionLabel${index}`
                                      ];
                                      return newErrors;
                                    });
                                  }}
                                />
                                <div className="col-span-3">
                                  {!["number", "decimal"].includes(
                                    conditionData?.question?.type,
                                  ) ? (
                                    <Dropdown
                                      className="w-full rounded-none"
                                      disabled={!question.show}
                                      placeholder="Select Operator"
                                      options={questionOperators}
                                      value={conditionData?.relation}
                                      onChange={(e) => {
                                        const updatedConditions = [
                                          ...question.conditions,
                                        ];
                                        updatedConditions[index].relation =
                                          e.target.value;
                                        setQuestion({
                                          ...question,
                                          conditions: updatedConditions,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <Dropdown
                                        className="rounded-none"
                                        placeholder="Comparison operators"
                                        options={[
                                          {
                                            label: "Equal",
                                            value: "equal",
                                          },
                                          {
                                            label: "Bigger than",
                                            value: "biggerNumber",
                                          },
                                          {
                                            label: "Smaller than",
                                            value: "smallerNumber",
                                          },
                                          {
                                            label: "Bigger than or equal",
                                            value: "biggerThanOrEqual",
                                          },
                                          {
                                            label: "Smaller than or equal",
                                            value: "smallerThanOrEqual",
                                          },
                                          {
                                            label: "Between",
                                            value: "between",
                                          },
                                        ]}
                                        value={
                                          conditionData?.numberComparisionCondition
                                        }
                                        onChange={(e) => {
                                          const updatedConditions = [
                                            ...question.conditions,
                                          ];
                                          updatedConditions[
                                            index
                                          ].numberComparisionCondition =
                                            e.target.value;
                                          setQuestion({
                                            ...question,
                                            conditions: updatedConditions,
                                          });
                                        }}
                                      />
                                    </>
                                  )}
                                  {questionErrors &&
                                    questionErrors.questionCondtion && (
                                      <p className="text-red-500">
                                        {questionErrors.questionCondtion}
                                      </p>
                                    )}
                                </div>
                                <div className="col-span-3">
                                  <div className="flex items-center md:gap-4">
                                    <label
                                      htmlFor="conditionAnswer"
                                      className="flex"
                                    >
                                      {question.questionCondtion}{" "}
                                      {isMobile ? "" : ":"}
                                    </label>
                                    {["number", "decimal"].includes(
                                      conditionData?.question?.type,
                                    ) ? (
                                      <>
                                        {(() => {
                                          if (
                                            conditionData?.question?.constraints
                                              ?.type === "height"
                                          ) {
                                            if (
                                              conditionData?.numberComparisionCondition ==
                                              "between"
                                            ) {
                                              return (
                                                <div className="flex flex-col gap-5">
                                                  <HeightInput
                                                    onHeightChange={(value) => {
                                                      const updatedConditions =
                                                        [
                                                          ...question.conditions,
                                                        ];
                                                      updatedConditions[
                                                        index
                                                      ].numberComparisionRange.min =
                                                        value;
                                                      setQuestion({
                                                        ...question,
                                                        conditions:
                                                          updatedConditions,
                                                      });
                                                    }}
                                                    heightValue={
                                                      conditionData
                                                        ?.numberComparisionRange
                                                        ?.min
                                                        ? conditionData
                                                            .numberComparisionRange
                                                            .min
                                                        : null
                                                    }
                                                  />
                                                  <HeightInput
                                                    onHeightChange={(value) => {
                                                      const updatedConditions =
                                                        [
                                                          ...question.conditions,
                                                        ];
                                                      updatedConditions[
                                                        index
                                                      ].numberComparisionRange.max =
                                                        value;
                                                      setQuestion({
                                                        ...question,
                                                        conditions:
                                                          updatedConditions,
                                                      });
                                                    }}
                                                    heightValue={
                                                      conditionData
                                                        ?.numberComparisionRange
                                                        ?.max
                                                        ? conditionData
                                                            .numberComparisionRange
                                                            .max
                                                        : null
                                                    }
                                                  />
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <HeightInput
                                                  onHeightChange={(value) => {
                                                    const updatedConditions = [
                                                      ...question.conditions,
                                                    ];
                                                    updatedConditions[
                                                      index
                                                    ].numberComparision = value;
                                                    setQuestion({
                                                      ...question,
                                                      conditions:
                                                        updatedConditions,
                                                    });
                                                  }}
                                                  heightValue={
                                                    conditionData?.numberComparision
                                                      ? conditionData.numberComparision
                                                      : null
                                                  }
                                                />
                                              );
                                            }
                                          } else {
                                            if (
                                              conditionData?.numberComparisionCondition ==
                                              "between"
                                            ) {
                                              return (
                                                <>
                                                  <InputNumber
                                                    useGrouping={false}
                                                    minFractionDigits={
                                                      conditionData?.question
                                                        ?.type === "number"
                                                        ? undefined
                                                        : 2
                                                    }
                                                    maxFractionDigits={
                                                      conditionData?.question
                                                        ?.type === "number"
                                                        ? undefined
                                                        : 3
                                                    }
                                                    className="rounded-none"
                                                    placeholder="From"
                                                    value={
                                                      conditionData
                                                        ?.numberComparisionRange
                                                        ?.min
                                                    }
                                                    onChange={(e) => {
                                                      const updatedConditions =
                                                        [
                                                          ...question.conditions,
                                                        ];
                                                      updatedConditions[
                                                        index
                                                      ].numberComparisionRange.min =
                                                        e.value;
                                                      setQuestion({
                                                        ...question,
                                                        conditions:
                                                          updatedConditions,
                                                      });
                                                    }}
                                                  />
                                                  <InputNumber
                                                    useGrouping={false}
                                                    minFractionDigits={
                                                      conditionData?.question
                                                        ?.type === "number"
                                                        ? undefined
                                                        : 2
                                                    }
                                                    maxFractionDigits={
                                                      conditionData?.question
                                                        ?.type === "number"
                                                        ? undefined
                                                        : 3
                                                    }
                                                    className="rounded-none"
                                                    placeholder="To"
                                                    value={
                                                      conditionData
                                                        ?.numberComparisionRange
                                                        ?.max
                                                    }
                                                    onChange={(e) => {
                                                      const updatedConditions =
                                                        [
                                                          ...question.conditions,
                                                        ];
                                                      updatedConditions[
                                                        index
                                                      ].numberComparisionRange.max =
                                                        e.value;
                                                      setQuestion({
                                                        ...question,
                                                        conditions:
                                                          updatedConditions,
                                                      });
                                                    }}
                                                  />
                                                </>
                                              );
                                            } else {
                                              return (
                                                <InputNumber
                                                  useGrouping={false}
                                                  minFractionDigits={
                                                    conditionData?.question
                                                      ?.type === "number"
                                                      ? undefined
                                                      : 2
                                                  }
                                                  maxFractionDigits={
                                                    conditionData?.question
                                                      ?.type === "number"
                                                      ? undefined
                                                      : 3
                                                  }
                                                  className="rounded-none"
                                                  value={
                                                    conditionData?.numberComparision
                                                  }
                                                  onChange={(e) => {
                                                    const updatedConditions = [
                                                      ...question.conditions,
                                                    ];
                                                    updatedConditions[
                                                      index
                                                    ].numberComparision =
                                                      e.value;
                                                    setQuestion({
                                                      ...question,
                                                      conditions:
                                                        updatedConditions,
                                                    });
                                                  }}
                                                />
                                              );
                                            }
                                          }
                                        })()}
                                      </>
                                    ) : (
                                      <Dropdown
                                        value={conditionData?.answer?.label}
                                        placeholder={"Select Answer"}
                                        dataKey="label"
                                        disabled={
                                          !conditionData?.question?.label
                                        }
                                        options={conditionData?.answerOptions}
                                        onChange={(e) => {
                                          const updatedConditions = [
                                            ...question.conditions,
                                          ];
                                          updatedConditions[
                                            index
                                          ].answer.label = e.target.value;
                                          setQuestion({
                                            ...question,
                                            conditions: updatedConditions,
                                          });
                                          // Remove the error message when the value changes
                                          setQuestionErrors((prevErrors) => {
                                            const newErrors = { ...prevErrors };
                                            delete newErrors[
                                              `answerLabel${index}`
                                            ];
                                            return newErrors;
                                          });
                                        }}
                                        className="w-full rounded-none"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="col-span-2 flex items-center gap-4 md:px-6">
                                  {question.conditions.length > 1 ? (
                                    <i
                                      className="pi pi-trash cursor-pointer text-red-500"
                                      onClick={() => deleteObject(index)}
                                    />
                                  ) : null}
                                  {index === question.conditions.length - 1 ? (
                                    isMobile ? (
                                      <Button
                                        icon="pi pi-plus"
                                        label="Add More Question"
                                        outlined
                                        className="flex w-full gap-0 px-2"
                                        onClick={() => handleAddCondition()}
                                      ></Button>
                                    ) : (
                                      <i
                                        className="pi pi-plus cursor-pointer text-green-500"
                                        onClick={() => handleAddCondition()}
                                      />
                                    )
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="flex">
                              <div className="basis-1/4">
                                {questionErrors &&
                                  questionErrors[`conditionLabel${index}`] && (
                                    <p className="text-red-500">
                                      {questionErrors[`conditionLabel${index}`]}
                                    </p>
                                  )}
                              </div>
                              <div className="basis-1/4">
                                {questionErrors &&
                                  questionErrors[`answerLabel${index}`] && (
                                    <p className="text-red-500">
                                      {questionErrors[`answerLabel${index}`]}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}

              {["number", "decimal"].includes(question.type) ? (
                <div className="p-field mt-10">
                  <Tooltip target=".custom-question-tooltip" />
                  <label className="text-gray-600">Numeric Settings</label>
                  <span className="pl-2 text-gray-500">
                    <i
                      className="custom-question-tooltip pi pi-info-circle text-xl"
                      data-pr-tooltip="This settings will only appear if you chose a number-type question"
                      data-pr-position="right"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                  <div className="my-4 rounded-lg border-slate-200 md:border md:px-6 md:py-4">
                    <div>
                      <div className="flex flex-col gap-4">
                        <div className="mb-5 mt-1 flex">
                          <div className="flex w-[192px] items-center font-medium">
                            Display type
                          </div>
                          <Dropdown
                            value={
                              question?.constraints?.type
                                ? question.constraints.type
                                : selectedNumericType
                            }
                            onChange={(e) => {
                              heightData.current = {
                                minFt: 0,
                                maxFt: 0,
                                minIn: 0,
                                maxIn: 0,
                              };

                              setSelectedNumericType(e.value);
                              setQuestion((prev) => {
                                const newConstraints = {};
                                if (prev?.constraints?.min)
                                  newConstraints.min = null;
                                if (prev?.constraints?.max)
                                  newConstraints.max = null;
                                return {
                                  ...prev,
                                  constraints: {
                                    ...newConstraints,
                                    type: e.value,
                                  },
                                };
                              });
                            }}
                            options={numberTypes}
                            className="w-[260px]"
                          />
                        </div>
                        <div className="flex gap-5">
                          <div className="flex items-center gap-2">
                            <Checkbox
                              name="forceMinimum"
                              inputId="forceMinimum"
                              checked={question?.constraints?.hasOwnProperty(
                                "min",
                              )}
                              onChange={(e) => {
                                setQuestionErrors((prevErrors) => {
                                  const newErrors = { ...prevErrors };
                                  delete newErrors?.constraints;
                                  return newErrors;
                                });
                                if (e.target.checked) {
                                  setQuestion({
                                    ...question,
                                    constraints: {
                                      ...question?.constraints,
                                      min: "",
                                    },
                                  });
                                } else {
                                  const { min, ...rest } =
                                    question?.constraints;
                                  setQuestion({
                                    ...question,
                                    constraints: { ...rest },
                                  });
                                }
                              }}
                            />
                            <label
                              htmlFor="forceMinimum"
                              className="cursor-pointer"
                            >
                              Force minimum
                            </label>
                          </div>
                          <div className="flex gap-2">
                            {question?.constraints?.type &&
                            question.constraints.type === "height" ? (
                              <HeightInput
                                onHeightChange={(value) => {
                                  setQuestion({
                                    ...question,
                                    constraints: {
                                      ...question?.constraints,
                                      min: value,
                                    },
                                  });
                                }}
                                heightValue={
                                  question?.constraints?.min
                                    ? question?.constraints?.min
                                    : null
                                }
                                disabled={
                                  !question?.constraints?.hasOwnProperty("min")
                                }
                              />
                            ) : (
                              <InputNumber
                                useGrouping={false}
                                keyfilter={/^\d+$/}
                                minFractionDigits={
                                  question.type === "decimal" ? 2 : undefined
                                }
                                maxFractionDigits={
                                  question.type === "decimal" ? 3 : undefined
                                }
                                value={
                                  typeof question?.constraints?.min === "object"
                                    ? null
                                    : question?.constraints?.min || null
                                }
                                onChange={(e) => {
                                  setQuestionErrors((prevErrors) => {
                                    const newErrors = { ...prevErrors };
                                    delete newErrors.constraints;
                                    return newErrors;
                                  });
                                  setQuestion({
                                    ...question,
                                    constraints: {
                                      ...question.constraints,
                                      min: e.value,
                                    },
                                  });
                                }}
                                disabled={
                                  !question?.constraints?.hasOwnProperty("min")
                                }
                                placeholder="Minimum"
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex gap-5">
                          <div className="flex items-center gap-2">
                            <Checkbox
                              name="forceMaximum"
                              inputId="forceMaximum"
                              checked={question?.constraints?.hasOwnProperty(
                                "max",
                              )}
                              onChange={(e) => {
                                setQuestionErrors((prevErrors) => {
                                  const newErrors = { ...prevErrors };
                                  delete newErrors?.constraints;
                                  return newErrors;
                                });
                                if (e.target.checked) {
                                  setQuestion({
                                    ...question,
                                    constraints: {
                                      ...question?.constraints,
                                      max: "",
                                    },
                                  });
                                } else {
                                  const { max, ...rest } =
                                    question?.constraints;
                                  setQuestion({
                                    ...question,
                                    constraints: { ...rest },
                                  });
                                }
                              }}
                            />
                            <label
                              htmlFor="forceMaximum"
                              className="cursor-pointer"
                            >
                              Force maximum
                            </label>
                          </div>
                          <div className="flex gap-2">
                            {question?.constraints?.type &&
                            question.constraints.type === "height" ? (
                              <HeightInput
                                onHeightChange={(value) => {
                                  setQuestion({
                                    ...question,
                                    constraints: {
                                      ...question?.constraints,
                                      max: value,
                                    },
                                  });
                                }}
                                heightValue={
                                  question?.constraints?.max
                                    ? question?.constraints?.max
                                    : null
                                }
                                disabled={
                                  !question?.constraints?.hasOwnProperty("max")
                                }
                              />
                            ) : (
                              <InputNumber
                                useGrouping={false}
                                keyfilter={/^\d+$/}
                                minFractionDigits={
                                  question.type === "decimal" ? 2 : undefined
                                }
                                maxFractionDigits={
                                  question.type === "decimal" ? 3 : undefined
                                }
                                value={
                                  typeof question?.constraints?.max === "object"
                                    ? null
                                    : question?.constraints?.max || null
                                }
                                onChange={(e) => {
                                  setQuestionErrors((prevErrors) => {
                                    const newErrors = { ...prevErrors };
                                    delete newErrors?.constraints;
                                    return newErrors;
                                  });
                                  setQuestion({
                                    ...question,
                                    constraints: {
                                      ...question?.constraints,
                                      max: e.value,
                                    },
                                  });
                                }}
                                disabled={
                                  !question?.constraints?.hasOwnProperty("max")
                                }
                                placeholder="Maximum"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {questionErrors?.constraints && (
                        <p className="mt-3 text-red-500">
                          {questionErrors.constraints}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </TabPanel>
          </TabView>
          <div className="p-field flex justify-end">
            <div className="mx-4 flex w-full flex-col-reverse justify-center md:flex-row md:justify-end md:gap-5">
              <div>
                <Button
                  className="mt-3 md:mt-8"
                  label="Cancel"
                  outlined
                  onClick={
                    editQuestion ? handleCancelEditingQuestion : handleCancel
                  }
                />
              </div>
              <div>
                <Button
                  className="mt-8"
                  label="Save Question"
                  onClick={
                    editQuestion ? handleEditQuestion : handleSaveQuestion
                  }
                />
              </div>
            </div>
          </div>
        </form>
      ) : (
        <RenderedQuestion
          question={initialQuestionState}
          formQuestions={formQuestions}
          setFormQuestions={setFormQuestions}
          index={currentIndex}
          onEdit={handleEditQuestionInForm}
          onDelete={handleRemoveQuestion}
          setEditQuestion={setEditQuestion}
          createNewLast={createNewLast}
          questionToEdit={questionToEdit}
          setQuestionToEdit={setQuestionToEdit}
          initialQuestionState={question}
          setQuestion={setQuestion}
          isQuestionInUse={isQuestionInUse}
        />
      )}
      <Dialog
        blockScroll
        draggable={false}
        visible={openCreateQuestionDialog}
        style={{ width: "80vw" }}
        onHide={() => SetOpenCreateQuestionDialog(false)}
      >
        <DataTable
          value={listOfQuestion}
          selectionMode="single"
          selection={selectedQuestion}
          onSelectionChange={(e) => setSelectedQuestion(e.value)}
        >
          <Column field="questionBody" header="Question Name" />
        </DataTable>
        <div className="mt-5 text-end">
          <Button
            label="Apply"
            onClick={handleApplyQuestion}
            disabled={!selectedQuestion}
          />
        </div>
      </Dialog>
    </>
  );
};
export default QuestionMaker;
