import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

function DialogEnterPin({
  setShowDialogEnterPin,
  showDialogEnterPin,
  setErrorMessage,
  errorMessage,
  onPinSubmit,
}) {
  const [PinEye, setPinEye] = useState(false);
  const handleOldPinClick = () => setPinEye(!PinEye);
  const defaultValues = {
    pinCode: "",
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues,
  });
  const onSubmit = async (formData) => {
    await onPinSubmit(formData.pinCode);
    reset();
  };

  const headerElement = (
    <div>
      <h4 className="font-inter text-title font-extrabold capitalize text-dark-purple">
        Enter PIN
      </h4>
      <p className="pt-1 font-inter text-base text-gray-500">
        Enter your PIN to confirm that you are placing this order.
      </p>
    </div>
  );
  const footerContent = (
    <div>
      <Button
        label="confirm"
        icon="pi pi-check"
        type="submit"
        form="createPinForm"
        loading={isSubmitting}
        autoFocus
        className="mt-9 w-full justify-center rounded-lg bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
      />
    </div>
  );

  return (
    <>
      <Dialog
        visible={showDialogEnterPin}
        modal
        draggable={false}
        header={headerElement}
        footer={footerContent}
        style={{ width: "35rem" }}
        onHide={() => {
          if (!showDialogEnterPin) return;
          setShowDialogEnterPin(false);
          setErrorMessage("");
          reset();
        }}
      >
        <form
          className="w-full"
          onSubmit={handleSubmit(onSubmit)}
          id="createPinForm"
        >
          <div>
            <div className="">
              <label className="block pb-2 font-Poppins text-base capitalize leading-loose text-gray-500">
                Provider PIN
              </label>
              <Controller
                name="pinCode"
                control={control}
                rules={{
                  required: "Please enter provider PIN",
                  pattern: {
                    value: /^\d{4,12}$/,
                    message: "PIN must be between 4 and 12 digits",
                  },
                }}
                render={({ field }) => (
                  <>
                    <span className="p-input-icon-left p-input-icon-right w-full">
                      <i className="pi pi-lock" />
                      <InputText
                        placeholder="PIN must be between 4 and 12 digits"
                        maxLength={12}
                        disabled={isSubmitting}
                        keyfilter="num"
                        className="w-full pb-2 pt-2"
                        name="pinCode"
                        type={!PinEye ? "password" : "text"}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          errorMessage && setErrorMessage("");
                        }}
                      />
                      {!PinEye ? (
                        <i
                          className="pi pi-eye-slash cursor-pointer"
                          onClick={handleOldPinClick}
                        />
                      ) : (
                        <i
                          className="pi pi-eye cursor-pointer"
                          onClick={handleOldPinClick}
                        />
                      )}
                    </span>
                  </>
                )}
              />
              {errors.pinCode && (
                <p className="text-red-500">{errors.pinCode.message}</p>
              )}
              {errorMessage && (
                <Message
                  severity="error"
                  text={errorMessage}
                  className="mt-9 w-full font-bold"
                />
              )}
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}

export default DialogEnterPin;
