const settings = [
  {
    id: "deposit-settings",
    icon: "pi pi-dollar",
    title: "Deposit Method",
    description:
      "Select your preferred deposit method to receive your payments",
    rendering: "/rendering/settings/DepositSettings/",
    visibilityCheck: ({ userRoles, isOrderingMode }) =>
      userRoles === "Admin" && !isOrderingMode,
    type: "practice",
  },
  {
    id: "edit-profile",
    icon: "pi pi-user-edit",
    title: "Edit Profile",
    description: "Update your profile information",
    rendering: "/rendering/settings/ProfileSettings",
    ordering: "/ordering/settings/ProfileSettings",
    visibilityCheck: () => true,
    type: "user",
  },
  {
    id: "security-settings",
    icon: "pi pi-lock",
    title: "Security Settings",
    description:
      "Manage your security settings, including your password and PIN",
    rendering: "/rendering/settings/security",
    ordering: "/ordering/settings/Security",
    visibilityCheck: () => true,
    type: "user",
  },
  {
    id: "provider-verification",
    icon: "pi pi-verified",
    title: "Provider Verification",
    description: "Manage provider verification information",
    ordering: "/ordering/settings/verifyProvider",
    visibilityCheck: ({ individualPracticeOwner, isOrderingMode }) =>
      individualPracticeOwner === "False" && isOrderingMode,
    type: "practice",
  },
  {
    id: "group-practice",
    icon: "pi pi-users",
    title: "Edit Group Practice Information",
    description: "Update your group practice information presented",
    rendering: "/rendering/settings/groupSettings/",
    ordering: "/ordering/settings/groupSettings/",
    visibilityCheck: ({ userRoles, entityType }) =>
      userRoles === "Admin" && entityType === "1",
    type: "practice",
  },
  {
    id: "individual-practice",
    icon: "pi pi-users",
    title: "Edit Individual Practice Information",
    description: "Update your individual practice information presented",
    rendering: "/rendering/settings/individualSetting",
    ordering: "/ordering/settings/individualSetting",
    visibilityCheck: ({ userRoles, entityType }) =>
      userRoles === "Admin" && entityType === "2",
    type: "practice",
  },
  {
    id: "orders-settings",
    icon: "pi pi-shopping-cart",
    title: "Orders Settings",
    description: "Edit your orders settings",
    rendering: "/rendering/settings/OrderSettings/",
    visibilityCheck: ({ userRoles, isOrderingMode }) =>
      userRoles === "Admin" && !isOrderingMode,
    type: "practice",
  },
  {
    id: "user-notifications",
    icon: "pi pi-bell",
    title: "Account Notifications",
    description: "Manage Email & SMS notifications",
    rendering: "/rendering/settings/userNotifications",
    ordering: "/ordering/settings/userNotifications",
    visibilityCheck: () => true,
    type: "user",
  },
  {
    id: "practice-notifications",
    icon: "pi pi-bell",
    title: "Practice Notifications",
    description: "Manage Email & SMS notifications for practice members",
    rendering: "/rendering/settings/practiceNotifications",
    ordering: "/ordering/settings/practiceNotifications",
    visibilityCheck: ({ userRoles }) => userRoles === "Admin",
    type: "practice",
  },
  {
    id: "privacy-preferences",
    icon: "pi pi-shield",
    title: "Privacy Preferences",
    description: "Manage your privacy and communication preferences",
    rendering: "/rendering/settings/privacy",
    ordering: "/ordering/settings/privacy",
    visibilityCheck: ({ twilioStatus }) => twilioStatus,
    type: "user",
  },
];

export const getVisibleSettings = (context) => {
  return settings.filter(
    (setting) =>
      setting.visibilityCheck(context) &&
      (context.isOrderingMode ? setting.ordering : setting.rendering),
  );
};

export const separateSettingsByType = (visibleSettings) => {
  return visibleSettings.reduce(
    (acc, setting) => {
      if (setting.type === "user") {
        acc.userSettings.push(setting);
      } else {
        acc.practiceSettings.push(setting);
      }
      return acc;
    },
    { userSettings: [], practiceSettings: [] },
  );
};
