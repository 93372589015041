import { useSwitchOrdering } from "../store";
import SettingsSection from "./SettingsSection";
import useTokenData from "../hooks/useTokenData";
import useTwilioStatus from "../hooks/useTwilioStatus";
import {
  getVisibleSettings,
  separateSettingsByType,
} from "./utility/settingsUtils";

function Settings() {
  const { twilioStatus } = useTwilioStatus();
  const { isOrderingMode } = useSwitchOrdering();
  const { individualPracticeOwner, userRoles, entityType } = useTokenData();

  const context = {
    isOrderingMode,
    userRoles,
    entityType,
    twilioStatus,
    individualPracticeOwner,
  };

  const visibleSettings = getVisibleSettings(context);
  const { userSettings, practiceSettings } =
    separateSettingsByType(visibleSettings);

  return (
    <div className="w-full py-4 sm:px-8">
      <h4 className="mb-4 text-center text-4xl font-bold sm:text-left">
        Settings
      </h4>
      <p className="mb-2 text-center text-gray-700 sm:text-left">
        Manage your account and practice details
      </p>

      {userSettings?.length > 0 && (
        <SettingsSection
          settings={userSettings}
          isOrderingMode={isOrderingMode}
          title="User Settings"
        />
      )}
      {practiceSettings?.length > 0 && (
        <SettingsSection
          settings={practiceSettings}
          isOrderingMode={isOrderingMode}
          title="Practice Settings"
        />
      )}
    </div>
  );
}

export default Settings;
